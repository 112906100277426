import Typography from "Atoms/Typography";
import { useState } from "react";
import OptionButton from "../optionButton/optionButton";
import OptionSelect from "../optionSelect/optionSelect";

import styles from "./facetSection.module.scss";

const FacetSection = ({ facetInfo, selectedFacetId, setSelectedFacetId }) => {
  const facets = facetInfo?.facets;
  const useSelect = facets?.length > 3 ? true : false;

  return (
    <div className={styles.container}>
      <Typography font={"bold"} size={1}>
        {facetInfo?.displayName}
      </Typography>
      {useSelect ? (
        <div className={styles.facetContainer}>
          <OptionSelect
            facetOptions={facets}
            selectedFacetId={selectedFacetId}
            setSelectedFacetId={setSelectedFacetId}
          />
        </div>
      ) : (
        <div className={styles.facetButtonContainer}>
          {facets?.map((facet) => {
            return (
              <OptionButton
                isSelected={selectedFacetId === facet.id}
                displayName={facet.name}
                onClick={() => setSelectedFacetId(facet.id)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FacetSection;
