import { useProductPageStore } from "../../contexts/ProductPageStore";

const ProductPageSuspense = ({ children, fallback }) => {
  const hydrated = useProductPageStore(x => x.isLazyDataHydrated);
  const csrFlag = useProductPageStore(x => x.csrFlag);

  if (!hydrated && Number(csrFlag)) {
    return fallback ? fallback : null;
  }

  return <>{children}</>;
};

export default ProductPageSuspense;
