import { NavigationContext } from "Clutch/Contexts/Navigation/NavigationContext";
import { useProductPageStore } from "./ProductPageStore";
import useRecentHistory from 'Clutch/Hooks/useRecentHistory'

import { useState, useContext, useEffect, useCallback } from 'react'

export default function ProductPageWiring({ set }) {
  const cachedTime = useProductPageStore(x => x.cachedTime)
  const edgeCacheDate = useProductPageStore(x => x.edgeCacheDate)
  const skuBaseNumber = useProductPageStore(x => x.skuBaseNumber)
  const needToCalculateQuantityBreak = useProductPageStore(x => x.needToCalculateQuantityBreak)
  const quantityRequested = useProductPageStore(x => x.quantityRequested)
  const updatePriceWithPriceBreak = useProductPageStore(x => x.updatePriceWithPriceBreak)
  const aPlusContentScrollToLock = useProductPageStore(x => x.aPlusContentScrollToLock)
  const readyForScrollData = useProductPageStore(x => x.readyForScrollData)
  const isDetailsAccordionOpen = useProductPageStore(x => x.isDetailsAccordionOpen)

  const navigationContext = useContext(NavigationContext);

  const [scrollStopped, setScrollStopped] = useState(false)

  const ScrollEnd = useCallback(() => {

    let scrollTimeout;

    clearTimeout(scrollTimeout);
    setTimeout(function () {
      setScrollStopped(true)
      window.removeEventListener('scroll', ScrollEnd)
    }, 1000)
  }, [])

  useEffect(() => {
    navigationContext.setPageType("PDP");
    // log out the time when the page was cached in vercel
    console.log('product service cached time: ', cachedTime)
    console.log('vercel html cached time: ', edgeCacheDate)
    useRecentHistory.AddToRecentHistory({ skuBaseNumber: skuBaseNumber });
    
  }, []);

  useEffect(() => {
    if (needToCalculateQuantityBreak) {
      updatePriceWithPriceBreak(quantityRequested)
    }
  }, [needToCalculateQuantityBreak])

  useEffect(() => {
    if (scrollStopped) {
      set((state) => {
        const { shouldOpenAccordion, ...rest } = state;
        return {
          ...rest,
          [`is${state.aPlusContentScrollToLock.accordionName}AccordionOpen`]: shouldOpenAccordion,
          aPlusContentScrollToLock: { active: false },
        };
      });
    }
  }, [scrollStopped]);

  useEffect(() => {
    if (aPlusContentScrollToLock?.active 
      && (!aPlusContentScrollToLock.waitForAccordionOpen || (readyForScrollData[aPlusContentScrollToLock.accordionName]))) {
      document.getElementById(aPlusContentScrollToLock.id).scrollIntoView({ behavior: 'smooth' })
      setScrollStopped(false)
      window.addEventListener('scroll', ScrollEnd);
    }
  }, [aPlusContentScrollToLock, readyForScrollData, isDetailsAccordionOpen])

}
