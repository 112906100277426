import React from "react";
import { ProductJsonLd, BreadcrumbJsonLd } from "next-seo";
import { useProductPageStore } from "../../contexts/ProductPageStore";

const JsonLD = ({}) => {
  const skuVariantNumber = useProductPageStore(x => x.skuVariantNumber);
  const retailLowPrice = useProductPageStore(x => x.retailLowPrice);
  const strippedInfoTab = useProductPageStore(x => x.strippedInfoTab);
  const metaDescription = useProductPageStore(x => x.metaDescription);
  const title = useProductPageStore(x => x.title);
  const images = useProductPageStore(x => x.images);
  const brand = useProductPageStore(x => x.brand);
  const rating = useProductPageStore(x => x.rating);
  const reviewCount = useProductPageStore(x => x.reviewCount);
  const manufacturerPartNumber = useProductPageStore(x => x.manufacturerPartNumber);
  const skuBaseNumber = useProductPageStore(x => x.skuBaseNumber);
  const gtin = useProductPageStore(x => x.gtin);
  const hasPriceRange = useProductPageStore(x => x.hasPriceRange);
  const retailHighPrice = useProductPageStore(x => x.retailHighPrice);
  const isGarageSale = useProductPageStore(x => x.isGarageSale);
  const microDataAvailability = useProductPageStore(x => x.microDataAvailability);
  const productPageUrl = useProductPageStore(x => x.productPageUrl);
  const breadcrumb = useProductPageStore(x => x.breadcrumb);
  const canonicalUrl = useProductPageStore(x => x.canonicalUrl);
  const indexOptions = useProductPageStore(x => x.indexOptions);


  //if there's not a sku variant number and there's not a price range, the json ld will always be invalid and cause errors in Google Search Console
  if (
    !skuVariantNumber &&
    !retailLowPrice
  )
    return false;

  const description = strippedInfoTab
    ? JSON.stringify(strippedInfoTab)
    : "";

  const disambiguatingDescription = metaDescription
    ? JSON.stringify(metaDescription)
    : "";

  const productName = title
    ? JSON.stringify(title)
    : "";
  return (
    <>
      <ProductJsonLd
        keyOverride={"product"}
        productName={productName.slice(1, productName.length - 1)}
        images={
          images && images[0]
            ? `https:${images[0]}`
            : ""
        }
        description={description.slice(1, description.length - 1)}
        disambiguatingDescription={disambiguatingDescription.slice(
          1,
          disambiguatingDescription.length - 1
        )}
        brand={
          brand && brand.brandName
            ? brand.brandName
            : ""
        }
        aggregateRating={
          rating > 0
            ? {
                ratingValue: rating,
                reviewCount: reviewCount,
              }
            : null
        }
        manufacturerName={
          brand && brand.brandName
            ? brand.brandName
            : ""
        }
        manufacturerLogo={
          brand && brand.brandImageUrl
            ? `https:${brand.brandImageUrl}`
            : ""
        }
        mpn={manufacturerPartNumber}
        sku={
          skuVariantNumber
            ? skuVariantNumber
            : skuBaseNumber
        }
        gtin8={
          gtin && gtin.length == 8
            ? gtin
            : null
        }
        gtin13={
          gtin && gtin.length == 13
            ? gtin
            : null
        }
        gtin14={
          gtin && gtin.length == 14
            ? gtin
            : null
        }
        //use 'offers' on a sku variant page, and 'aggregateOffer' on base pages
        offers={
          skuVariantNumber
            ? [
                {
                  price: hasPriceRange
                    ? ""
                    : retailLowPrice,
                  priceCurrency: "USD",
                  itemCondition: isGarageSale
                    ? "https://schema.org/DamagedCondition"
                    : "https://schema.org/NewCondition",
                  availability: microDataAvailability,
                  url: productPageUrl,
                  seller: {
                    name: "Speedway Motors",
                  },
                },
              ]
            : null
        }
        aggregateOffer={
          skuVariantNumber
            ? null
            : {
                lowPrice: retailLowPrice,
                highPrice: hasPriceRange
                  ? retailHighPrice
                  : retailLowPrice,
                priceCurrency: "USD",
                offerCount: (
                  (indexOptions || []).map(
                    (x) => x.indexOptionValues.length
                  ) || [1]
                ).length
                  ? (
                      indexOptions.map(
                        (x) => x.indexOptionValues.length
                      ) || [1]
                    ).reduce((a, b) => a * b)
                  : 1,
              }
        }
      />

      <BreadcrumbJsonLd
        keyOverride={"breadcrumb"}
        itemListElements={(breadcrumb || []).map((crumb) => {
          return {
            position: crumb.ordinality + 1,
            name: crumb.displayText,
            item: crumb.url
              ? `https://www.speedwaymotors.com${crumb.url}`
              : canonicalUrl,
          };
        })}
      />
    </>
  );
};

export default JsonLD;
