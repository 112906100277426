import React, { useContext, useRef } from 'react'

import Breadcrumb from 'Clutch/Molecules/Breadcrumb/Breadcrumb'
import { useProductPageStore } from '../../contexts/ProductPageStore';

const BreadcrumbWrapper = ({ }) => {
  const renderCounter = useRef(0);
  renderCounter.current = renderCounter.current + 1
  console.debug(`Breadcrumb Component Renders: ${renderCounter.current}`)
  const breadcrumbFromContext = useProductPageStore(x => x.breadcrumb)

  if((breadcrumbFromContext || []).length === 0)
    return

  const breadcrumb = breadcrumbFromContext.filter(crumb => crumb)

  if((breadcrumb || []).length === 0)
    return

  return (
    <Breadcrumb breadcrumb={breadcrumb} />
  )
}

export default BreadcrumbWrapper