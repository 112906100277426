import React, { useContext, useEffect } from 'react'
import Head from 'next/head'
import { NextSeo } from 'next-seo'

import useFacebook from 'Clutch/Hooks/useFacebook'

import { useUserStore } from "Clutch/Stores/UserStore/UserStore";
import { useProductPageStore } from '../../contexts/ProductPageStore';


const HeadContent = ({ }) => {
  const title = useProductPageStore((state) => state.title);
  const metaKeyword = useProductPageStore((state) => state.metaKeyword);
  const imageSize = useProductPageStore((state) => state.imageSize);
  const metaDescription = useProductPageStore((state) => state.metaDescription);
  const canonicalUrl = useProductPageStore((state) => state.canonicalUrl);
  const absoluteProductPageUrl = useProductPageStore((state) => state.absoluteProductPageUrl);
  const images = useProductPageStore((state) => state.images);
  const skuVariantNumber = useProductPageStore((state) => state.skuVariantNumber);
  const strippedInfoTab = useProductPageStore((state) => state.strippedInfoTab);
  const getShippingEstimate = useProductPageStore((state) => state.getShippingEstimate);
  const getLastPurchasedDateForSku = useProductPageStore((state) => state.getLastPurchasedDateForSku);
  const bustStaticPage = useProductPageStore((state) => state.bustStaticPage);
  const hydrated = useUserStore((x) => x.context.hydrated);
  const isBot = useUserStore((x) => x.context.isBot);
  const userGuid = useUserStore((x) => x.context.userGuid);
  const acquisitionTypeHydrated = useUserStore((x) => x.context.acquisitionTypeHydrated);
  const zipCode = useUserStore((x) => x.context.zipCode);

  const sendFacebookEvent = useFacebook()

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dispatchEvent(new CustomEvent("clarityautomate.initialized"));
    }
  });

  useEffect(() => {
    if (hydrated) {
      if (!isBot()) {
        bustStaticPage(sendFacebookEvent)
      }

      if (!isBot() && userGuid && skuVariantNumber) {
        getLastPurchasedDateForSku(userGuid, skuVariantNumber)
      }
    }
  }, [hydrated, userGuid , acquisitionTypeHydrated])

  useEffect(() => {

    if (hydrated && !isBot()) {
      if (skuVariantNumber) {
        const zipCodeValidated = zipCode && !isNaN(zipCode) ? zipCode : '68528'
        getShippingEstimate(skuVariantNumber, zipCodeValidated)
      }
    }

  }, [skuVariantNumber, hydrated, zipCode])

  return (
    <>
      <NextSeo
        title={title}
        description={metaDescription}
        canonical={(canonicalUrl || '').length ? canonicalUrl : null}
        noindex={false}
        nofollow={false}
        openGraph={{
          url: absoluteProductPageUrl,
          title: title,
          description: strippedInfoTab,
          images: [
            {
              url: (images || []).length ? `https:${images[0]}` : '',
              width: imageSize,
              height: imageSize,
              alt: title
            }
          ],
          site_name: 'Speedway Motors',
          type: 'product'
        }}
        twitter={{
          site: '@SpeedwayMotors',
          cardType: 'summary',
        }}
        additionalMetaTags={[{
          name: 'viewport',
          content: 'width=device-width,minimum-scale=1,initial-scale=1'
        }, {
          name: 'keywords',
          content: metaKeyword
        }, {
          name: 'language',
          content: 'english'
        }, {
          name: 'format-detection',
          content: 'telephone=no'
        }]}
        additionalLinkTags={[
          {
            rel: 'shortcut icon',
            href: '/favicon.ico',
          }, {
            rel: 'icon',
            href: '/favicon.ico',
            sizes: '152x152'
          }
        ]}
      />
    </>

  )
}

export default HeadContent
