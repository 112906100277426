const createCheckFitmentRequest = (customerProjects) => {
  const checkFitmentRequest = customerProjects?.map((customerProject) => {
    const request = {};
    const engineIds = {};

    request.requestIdentifier = customerProject.projectId;

    if (customerProject.vehicleBaseId)
      request.vehicleBaseId = customerProject.vehicleBaseId;
    if (customerProject.raceTypeId)
      request.raceTypeId = customerProject.raceTypeId;
    if (customerProject.engineManufacturerId)
      engineIds.manufacturerId = customerProject.engineManufacturerId;
    if (customerProject.engineMakeId)
      engineIds.makeId = customerProject.engineMakeId;
    if (customerProject.engineSeriesId)
      engineIds.seriesId = customerProject.engineSeriesId;
    if (customerProject.engineConfigurationId)
      engineIds.configurationId = customerProject.engineConfigurationId;
    if (customerProject.engineQualifierId)
      engineIds.qualifierId = customerProject.engineQualifierId;
    if (customerProject.engineVersionId)
      engineIds.versionId = customerProject.engineVersionId;
    if (customerProject.engineDisplacementId)
      engineIds.displacementId = customerProject.engineDisplacementId;
    if (customerProject.engineCodeId)
      engineIds.codeId = customerProject.engineCodeId;
    if (customerProject.engineCamshaftTypeId)
      engineIds.camshaftTypeId = customerProject.engineCamshaftTypeId;
    if (customerProject.engineFuelSystemId)
      engineIds.fuelSystemId = customerProject.engineFuelSystemId;

    if (Object.keys(engineIds).length) request.engineIds = engineIds;

    return request;
  });
  return checkFitmentRequest;
};

export { createCheckFitmentRequest };
