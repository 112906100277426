"use client"

import React from "react";
import dynamic from "next/dynamic";
import classNames from "classnames";
import ErrorBoundary from "Clutch/UtilityComponents/ErrorBoundary";
import { useIntersectionObserver } from "@speedwaymotors/clutch/Hooks/useIntersectionObserver";

import Panel from "Clutch/Atoms/Panel";
import Gutters from "Clutch/Atoms/Gutters";
import StickyHeader from "Clutch/Components/StickyHeader";
import BreadcrumbWrapper from "../../../../../../containers/breadcrumb/breadcrumb";
import AddToList from "../../../../../../containers/addToList/addToList";
import { useLazyFeatureFlag } from 'Clutch/Hooks/useFeatureFlag/useFeatureFlag';
import ProductPageSuspense from "../../../../../../containers/productPageSuspense/productPageSuspense";
import { SummaryFallback, SkeletonWithMargin, SkeletonAsideColumn } from "../../../../../../containers/productPageSuspense/SkeletonFallbackStyles";

import HeadContent from "../../../../../../containers/head/head";
import JsonLD from "../../../../../../containers/json-ld/jsonLD";


//#region 
const Gallery = dynamic(() =>
  import("../../../../../../chunks/chunk1").then((module) => module.Gallery)
);
const Offer = dynamic(() =>
  import("../../../../../../chunks/chunk1").then((module) => module.Offer)
);

const Summary = dynamic(() =>
  import("../../../../../../chunks/chunk1").then((module) => module.Summary)
);
const Details = dynamic(() =>
  import("../../../../../../chunks/chunk1").then((module) => module.Details)
);
const Specs = dynamic(() =>
  import("../../../../../../chunks/chunk1").then((module) => module.Specs)
);

const Contact = dynamic(() =>
  import("../../../../../../chunks/chunk1").then((module) => module.Contact)
);
const InstallationDetails = dynamic(() =>
  import("../../../../../../chunks/chunk1").then((module) => module.InstallationDetails),
);

const AplusCardContent = dynamic(
  () =>
    import("../../../../../../chunks/chunk1").then(
      (module) => module.AplusCardContent
    ),
  { ssr: false }
);
const Ratings = dynamic(
  () => import("../../../../../../chunks/chunk1").then((module) => module.Ratings),
  { ssr: false }
);
const QuestionsAndAnswers = dynamic(
  () =>
    import("../../../../../../chunks/chunk1").then(
      (module) => module.QuestionsAndAnswers
    ),
  { ssr: false }
);
const VideosAndArticles = dynamic(
  () =>
    import("../../../../../../chunks/chunk1").then(
      (module) => module.VideosAndArticles
    ),
  { ssr: false }
);
const ProductCompare = dynamic(
  () =>
    import("../../../../../../chunks/chunk1").then(
      (module) => module.ProductCompare
    ),
  { ssr: false }
);

const StickyHeaderContent = dynamic(
  () =>
    import("../../../../../../chunks/chunk1").then(
      (module) => module.StickyHeaderContent
    ),
  { ssr: false }
);
const MobileStickyAtc = dynamic(
  () =>
    import("../../../../../../chunks/chunk1").then(
      (module) => module.MobileStickyAtc
    ),
  { ssr: false }
);

const BottomRecommendations = dynamic(
  () =>
    import("../../../../../../chunks/chunk1").then(
      (module) => module.BottomRecommendations
    ),
  { ssr: false }
);
const SummaryRecommendations = dynamic(
  () =>
    import("../../../../../../chunks/chunk1").then(
      (module) => module.SummaryRecommendations
    ),
  { ssr: false }
);
const AsideRecommendations = dynamic(
  () =>
    import("../../../../../../chunks/chunk1").then(
      (module) => module.AsideRecommendations
    ),
  { ssr: false }
);
const KitRecommendations = dynamic(
  () =>
    import("../../../../../../chunks/chunk1").then(
      (module) => module.KitRecommendations
    ),
  { ssr: false }
);
const AlternateRecommendations = dynamic(
  () =>
    import("../../../../../../chunks/chunk1").then(
      (module) => module.AlternateRecommendations
    ),
  { ssr: false }
);
const SpeedwayAlternateRecommendations = dynamic(
  () =>
    import("../../../../../../chunks/chunk1").then(
      (module) => module.SpeedwayAlternateRecommendations
    ),
  { ssr: false }
);

//#endregion

import GetStaticPaths from "../../../../../../lib/getStaticPaths";
import GetStaticProps from "../../../../../../lib/getStaticProps";

import styles from "./product.module.scss";

import { ProductPageProvider } from "../../../../../../contexts/ProductPageStore";
import AdditionalFacetDrawer from "../../../../../../containers/drawers/additionalFacetDrawer/additionalFacetDrawer";
//import EngineFitmentFeedbackWrapper from "../../../../../containers/engineFitmentFeedback/engineFitmentFeedbackWrapper";

const InStorePickupDrawer = dynamic(
  () =>
    import("../../../../../../chunks/chunk1").then(
      (module) => module.InStorePickupDrawer
    ),
  { ssr: false }
);
const WhatsInTheKitDrawer = dynamic(
  () =>
    import("../../../../../../chunks/chunk1").then(
      (module) => module.WhatsInTheKitDrawer
    ),
  { ssr: false }
);
const FitmentDrawer = dynamic(
  () =>
    import("../../../../../../chunks/chunk1").then(
      (module) => module.FitmentDrawer
    ),
  { ssr: false }
);
const GarageSaleDrawer = dynamic(
  () =>
    import("../../../../../../chunks/chunk1").then(
      (module) => module.GarageSaleDrawer
    ),
  { ssr: false }
);
const EngineFitmentFeedback = dynamic(
  () =>
    import("../../../../../../chunks/chunk1").then(
      (module) => module.EngineFitmentFeedbackWrapper
    ),
  { ssr: false }
);


export async function getStaticPaths() {
  return GetStaticPaths();
}

export async function getStaticProps({ params }) {
  return await GetStaticProps(params);
}

export default function ProductPage(props) {
  const contextObject = {
    ...props,
  };

  const summaryRef = useIntersectionObserver('summaryInView');
  const summaryRecommendationsRef = useIntersectionObserver('summaryRecommendationsInView');
  const aplusCardContentRef = useIntersectionObserver('aplusCardContentInView');
  const specsRef = useIntersectionObserver('specsInView');
  const ratingsRef = useIntersectionObserver('ratingsInView');
  const qnaRef = useIntersectionObserver('qnaInView');
  const compareRef = useIntersectionObserver('compareInView');

  const compareBoostEnabled = useLazyFeatureFlag(props.brand?.brandName && !props.brand?.isHouseBrand ? "PDP_Compare_Tool_Boosting" : "placeholderdontdelete")

  return (
    <ProductPageProvider value={contextObject}>
      <HeadContent />
      <ProductPageSuspense>
        <JsonLD />
      </ProductPageSuspense>

      <Panel className={styles.pdp_layout}>
        <main
          className={styles.pdp_main}
          data-productcachedate={contextObject.cachedTime}
          data-edgecachedate={contextObject.edgeCacheDate}
        >
          <header
            className={classNames(
              styles.pdp_layout_breadcrumb,
              styles.pdp_above_fold
            )}
            id={"pdp_breadcrumb"}
          >
            <Panel>
              <BreadcrumbWrapper />
            </Panel>
          </header>
          <div
            className={classNames(
              styles.pdp_layout_vert,
              styles.pdp_above_fold
            )}
          >
            <AddToList
              className={styles.pdp_add_to_list}
              dataTestId={"pdp_add_to_list_addtl_mobile_placement"}
            />
            <Gutters className={styles.pdp_offer_gallery_gutter}>
              <div className={styles.pdp_layout_main}>
                <section
                  aria-label={"Image Gallery"}
                  className={styles.pdp_layout_gallery}
                  id={"pdp_gallery"}
                >
                  <ErrorBoundary>
                    <Gallery />
                  </ErrorBoundary>
                </section>
                <div className={styles.pdp_layout_spacer} />
                <section
                  aria-label={"Offer"}
                  className={styles.pdp_layout_offer}
                  id={"pdp_offer"}
                >
                  <Offer />
                </section>
              </div>
            </Gutters>
          </div>
          <Panel
            className={classNames(
              styles.pdp_layout_vert,
              styles.pdp_layout_summary
            )}
            id={"pdp_summary"}
          >
            <Gutters>
              <section
                aria-label={"Summary"}
                className={styles.pdp_layout_main}
                ref={summaryRef}
              >
              <ProductPageSuspense fallback={<SummaryFallback/>}>
                <Summary />
              </ProductPageSuspense>
              </section>
            </Gutters>
          </Panel>
          {compareBoostEnabled ? (
            <div ref={compareRef}>
            <Gutters >
              <section
                aria-label={"Product Compare"}
                className={styles.pdp_compare}
                id={"pdp_productcompare"}
              >
                <ProductCompare />
              </section>
            </Gutters>
            </div>
          ) : (
            <section
              aria-label={"Recommendations"}
              className={classNames(
                styles.pdp_layout_main,
                styles.pdp_layout_summary_recommendations
              )}
              id={"pdp_summary_recommendations"}
              ref={summaryRecommendationsRef}
            >
              <SummaryRecommendations keywords={contextObject.title} />
            </section>
          )}
          <Panel
            className={classNames(
              styles.pdp_layout_vert,
              styles.pdp_below_fold
            )}
          >
            <div
              className={styles.pdp_layout_vert}
              id={"pdp_card_aplus"}
              ref={aplusCardContentRef}
            >
              <ProductPageSuspense>
                <AplusCardContent />
              </ProductPageSuspense>
            </div>
            <Gutters>
              <aside className={styles.pdp_layout_aside} id={"pdp_aside"}>
                <ProductPageSuspense fallback={<SkeletonAsideColumn />}>

                  <InstallationDetails />
                  <AsideRecommendations keywords={contextObject.title} />
                  <AlternateRecommendations keywords={contextObject.title} />
                  <SpeedwayAlternateRecommendations
                    keywords={contextObject.title}
                  />
                  <Contact />
                  <KitRecommendations keywords={contextObject.title} />
                </ProductPageSuspense>
              </aside>
              <section
                aria-label={"Details"}
                className={styles.pdp_layout_cards}
                id={"pdp_details"}
              >
                <ProductPageSuspense fallback={<SkeletonWithMargin height={275} fullWidth/>}>
                  <Details/>
                </ProductPageSuspense>
              </section>
              <section
                aria-label={"Specifications"}
                className={styles.pdp_layout_cards}
                id={"pdp_specs"}
                ref={specsRef}
              >
                <ProductPageSuspense fallback={<SkeletonWithMargin height={1500} fullWidth/>}>
                  <Specs />
                </ProductPageSuspense>
              </section>
              <section
                aria-label={"Ratings and Reviews"}
                className={styles.pdp_layout_cards}
                id={"pdp_ratings"}
                ref={ratingsRef}
              >
                  <ProductPageSuspense fallback={<SkeletonWithMargin height={200} fullWidth/>}>
                  <Ratings/>
                </ProductPageSuspense>
              </section>
              <section
                aria-label={"Questions and Answers"}
                className={styles.pdp_layout_cards}
                id={"pdp_qna"}
                ref={qnaRef}
              >
                <ProductPageSuspense fallback={<SkeletonWithMargin height={90} fullWidth/>}>
                  <QuestionsAndAnswers/>
                </ProductPageSuspense>
              </section>
              <section
                aria-label={"Videos and Articles"}
                className={styles.pdp_layout_cards}
                id={"pdp_videosAndArticles"}
              >
                <ProductPageSuspense fallback={<SkeletonWithMargin height={90} fullWidth/>}>
                  <VideosAndArticles/>
                </ProductPageSuspense>
              </section>
            </Gutters>
          </Panel>
          {compareBoostEnabled ? (
            <section
              aria-label={"Recommendations"}
              className={classNames(
                styles.pdp_layout_main,
                styles.pdp_layout_summary_recommendations
              )}
              id={"pdp_summary_recommendations"}
              ref={summaryRecommendationsRef}
            >
              <SummaryRecommendations keywords={contextObject.title} />
            </section>
          ) : (
            <Gutters >
              <section
                aria-label={"Product Compare"}
                className={styles.pdp_compare}
                ref={compareRef}
                id={"pdp_productcompare"}
              >
                <ProductCompare />
              </section>
            </Gutters>
          )}
        </main>
        <BottomRecommendations />
      </Panel>

      <ProductPageSuspense>
        <StickyHeader triggerId={"pdp-add-to-cart-button"}>
          <Gutters noVerticalPadding>
            <StickyHeaderContent />
          </Gutters>
        </StickyHeader>

        <MobileStickyAtc triggerId={"pdp_summary"} />
        <FitmentDrawer />
        <AdditionalFacetDrawer/>
        <GarageSaleDrawer />
        <InStorePickupDrawer />
        <WhatsInTheKitDrawer />
        <EngineFitmentFeedback />
      </ProductPageSuspense>
    </ProductPageProvider>
  );
}