import React, { useContext } from "react";
import { useProductPageStore } from "../../contexts/ProductPageStore";
import { AddToCartExperienceContext } from "Clutch/Contexts/AddToCartExperienceContext/AddToCartExperienceContext";
import Svg from "Clutch/Atoms/Svg";
import TextLink from "Clutch/Atoms/TextLink";
import Card from "Clutch/Atoms/Card"
import FeatureFlagWrapper from 'Clutch/UtilityComponents/FeatureFlagWrapper'

import styles from './addToList.module.scss'

const AddToList = ({className, dataTestId}) => {
  const skuVariantNumber = useProductPageStore(state => state.skuVariantNumber);
  const skuBaseNumber = useProductPageStore(state => state.skuBaseNumber);
  const quantityRequested = useProductPageStore(state => state.quantityRequested);
  const title = useProductPageStore(state => state.title);
  const images = useProductPageStore(state => state.images);
  const segmentCategorization = useProductPageStore(state => state.segmentCategorization);
  const brand = useProductPageStore(state => state.brand?.brandName);
  const price = useProductPageStore(state => state.price);
  const productPageUrl = useProductPageStore(state => state.productPageUrl);
  const isIndexOptionKit = useProductPageStore(state => state.isIndexOptionKit);
  const errorIndexOptions = useProductPageStore(state => state.errorIndexOptions);

  const addToCartExperienceContext = useContext(AddToCartExperienceContext);

  const itemToAddToList = {
    skuVariantNumber: skuVariantNumber,
    skuBaseNumber: skuBaseNumber,
    quantityRequested: parseInt(quantityRequested),
    title: title,
    images: images,
    segmentCategory: segmentCategorization,
    brand: brand?.brandName,
    price: price,
    url: productPageUrl
  };

  return (
    <FeatureFlagWrapper ff={"Add_To_List_Badge_Expanded_PDP"}>
    <div className={className}>
      <Card
        className={styles.pdp_atl_background}
        layer={0}
        noPadding
        data-testid={dataTestId ?? "pdp-add-to-list"}
        onClick={(e) => {
          e.preventDefault();
          isIndexOptionKit
            ? addToCartExperienceContext.showKitDetailsDrawer({ list: true })
            : skuVariantNumber === ""
            ? errorIndexOptions()
            : addToCartExperienceContext.showListDrawer([itemToAddToList]);
        }}
      >
        <Svg
          size={1.25}
          tone={"subtle"}
          icon={"heart"}
          className={styles.pdp_atc_aid_icon}
        />
      </Card>
      </div>
    </FeatureFlagWrapper>
  );
};

export default AddToList