// SkeletonFallbackStyles.js
import styled from 'styled-components';
import Skeleton from 'Clutch/Atoms/Skeleton';

export const SkeletonWithMargin = styled(Skeleton)`
  margin: 1rem 0;
`;

const StyledSummaryWrapper = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  width: 100%;

  .skeleton-1 {
    min-width: 530px;
  }

  .skeleton-group {
    display: flex;
    gap: 30px;
    flex-wrap: nowrap; 
    width: 100%;
  }

  @media (min-width: 1336px) {
    flex-wrap: nowrap;
  }
`;

const SkeletonColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SummaryFallback = () => (
  <StyledSummaryWrapper>
    <Skeleton className="skeleton-1" height={400} fullWidth />
    <div className="skeleton-group">
      <Skeleton className="skeleton-2" height={400} fullWidth />
      <Skeleton className="skeleton-3" height={400} fullWidth />
    </div>
  </StyledSummaryWrapper>
);

export const SkeletonAsideColumn = () => (
  <SkeletonColumnWrapper>
    <SkeletonWithMargin height={500} fullWidth />
    <SkeletonWithMargin height={500} fullWidth />
    <SkeletonWithMargin height={200} fullWidth />
    <SkeletonWithMargin height={550} fullWidth />
  </SkeletonColumnWrapper>
);
